import { derived, writable } from 'svelte/store';
import type { Product, ProductionCategory } from './types/types';

export const worker = writable(null);

export const products = writable<Product[]>([]);

export const categorySelected = writable<ProductionCategory | null>(null);

/**
 * Stores the hierarchical list of Production Categories.
 */
export const categories = writable<ProductionCategory[]>([]);

export const filteredProducts = derived(
	[categorySelected, products],
	([$categorySelected, $products]) => {
		return $categorySelected
			? $products.filter(p => p.category.id === $categorySelected.id)
			: $products.filter(p => p.category.id === null);
	},
);
