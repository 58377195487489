<script lang="ts">
	import { beforeUpdate, onMount } from 'svelte';
	import { push } from 'svelte-spa-router';
	import { boxRowsOpen, previousShippingLabel, order, printServiceStatus, queue, worker } from '../stores';
	import Button from '../components/Button.svelte';
	import { Modal, ModalBody, ModalHeader, Row } from '@sveltestrap/sveltestrap';
	import QuantitySelect from '../components/QuantitySelect.svelte';
	import OrderItem from '../components/OrderItem.svelte';
	import BundleElement from '../components/Bundle.svelte';
	import BoxRow from '../components/BoxRow.svelte';
	import { slide } from 'svelte/transition';
	import type {
		ApiResponse, CustomerOrderData,
		Item
	} from '../types/types';

	import { addAlpha } from '../utils/colorUtils';
	import { PrinterService } from '../utils/printerUtils';
	import {
		addGift,
		addNonExistingItem,
		isCompleted,
		unrelatedWarehouseWorker,
		updateOrder
	} from '../utils/salesOrdersUils';

	let audio = new Audio(
		"client/modules/expedition-portal/lib/expedition-portal/barcode-scaner-sound.mp3"
	);

	const giftScannedSound = new Audio(
		"client/modules/expedition-portal/lib/expedition-portal/gift-added-sound.mp3"
	);

	let returnModal = false;
	let message = "";
	let open = false;
	let openBoxAdd = false;
	let openComplete = false;
	let openBundleItem = false;
	let skip = false;
	let openNonExistItem = false;
	let alertModal = false;
	let allowForceComplete = false; // New flag
	let printerService: PrinterService | null = null;

	let barcodeInput = "";
	let barcodeTimer: number;

	let disableScan = false;
	let nonExistItemEan: string;
	let nonExistItemEanCount: number;
	let giftItem: Item;
	let giftItemOverflow: number;
	let boxOverflowQuantity: number;
	let boxOverflow: Item;
	let decidedOverflow: string;
	let decidedEan: string;

	var forcedComplete = false;
	const toggleAlertModal = (alertMessage = "") => {
		message = alertMessage;
		disableScan = !disableScan;
		return (alertModal = !alertModal);
	};

	try {
		printerService = new PrinterService();
	} catch (e: any) {
		toggleAlertModal("Nepodařilo se vytisknout štítek: " + e.message);
	}

	if ($worker == null) {
		push("/");
	}
	if ($order == null) {
		push("/queue");
	}

	onMount(() => {
		const initialize = async () => {
			window.addEventListener("keydown", handleBarcodeScanned);
		};

		initialize();

		return () => {
			window.removeEventListener("keydown", handleBarcodeScanned);
		};
	});

	function handleBarcodeScanned(event: KeyboardEvent) {
		if (disableScan) {
			return;
		}
		if ((event.key >= '0' && event.key <= '9') || event.key === 'Enter') {
			console.log("got keyCode", event.key);

			clearTimeout(barcodeTimer);
			if (event.key === 'Enter') {
				processBarcode();
				return;
			}
			barcodeInput += event.key;
			barcodeTimer = window.setTimeout(processBarcode, 100);
		}
	}

	function processBarcode() {
		if (barcodeInput === "") {
			return;
		}
		audio.currentTime = 0;
		audio.play();
		//console.log("processing barcode", barcodeInput);
		if (quantity === "_" || quantity === "0") {
			return;
		}
		const existBundle = $order.bundles.find((bundle) =>
			bundle.bundleItems.find(
				(item) =>
					(item.ean === barcodeInput ||
						item.ean === "0" + barcodeInput) &&
					item.status !== "completed" &&
					item.scannedQuantity + parseInt(quantity) <=
					item.orderQuantity
			)
		);

		const existItem = $order.items.find(
			(item) =>
				(item.ean === barcodeInput ||
					item.ean === "0" + barcodeInput) &&
				(item.status !== "completed" || item.type === "KRABICE")
		);

		if (existBundle && existItem) {
			handleBundleItems(barcodeInput, quantity);
			quantity = "1";
			barcodeInput = "";
		} else if (existBundle) {
			handleBundleItems(barcodeInput, quantity);
			quantity = "1";
			barcodeInput = "";
		} else {
			handleItems(barcodeInput, quantity);
			quantity = "1";
			barcodeInput = "";
		}
	}

	let quantity = "1";
	beforeUpdate(() => {
		clearTimeout(barcodeTimer);
	});

	function handleItems(barcode: string, quantity: string) {
		order.update(orderOld => {
			const order = JSON.parse(JSON.stringify(orderOld)) as CustomerOrderData;
			let filteredItems = order.items.filter(
				(item) =>
					(item.ean === barcode || item.ean === "0" + barcode) &&
					(item.status !== "completed" || item.type === "KRABICE")
			);
			if (filteredItems.length === 0) {
				filteredItems = order.items.filter(
					(item) =>
						item.ean === barcode || item.ean === "0" + barcode
				);
			}
			if (filteredItems.length === 0) {
				nonExistItemEan = barcode;
				nonExistItemEanCount = parseInt(quantity);
				toggleNonExistItem();
				return order;
			}
			let item: Item;
			if (filteredItems.length === 1) {
				item = filteredItems[0];
			} else if (filteredItems.length > 1) {
				item = filteredItems.find((item) => item.isGift !== true);
			}

			if (item.type !== "KRABICE") {
				if (
					item.scannedQuantity + parseInt(quantity) >
					item.orderQuantity
				) {
					giftItem = item;
					if (item.isGift) {
						giftItemOverflow = parseInt(quantity);
					} else {
						giftItemOverflow =
							item.scannedQuantity +
							parseInt(quantity) -
							item.orderQuantity;
						item.scannedQuantity = item.orderQuantity;
					}
					toggle();
					item.status = "completed";
					quantity = "1";
					return order;
				}
				item.scannedQuantity = item.scannedQuantity + parseInt(quantity);
				if (item.scannedQuantity >= item.orderQuantity) {
					item.status = "completed";
				} else {
					item.status = "not-scanned";
				}
			} else {
				//console.log("box");
				let boxCount =
					order.items
						.filter((item) => item.type === "KRABICE")
						.reduce((acc, item) => acc + item.scannedQuantity, 0) +
					parseInt(quantity);
				if (boxCount > 1) {
					toggleBoxAdd();
					boxOverflowQuantity = parseInt(quantity);
					boxOverflow = item;
					quantity = "1";
					return order;
				} else if (boxCount === 1) {
					order.boxCount += parseInt(quantity);
					order.boxStatus = "completed";
					item.scannedQuantity += parseInt(quantity);
					boxRowsOpen.set(false);
				} else {
					order.boxStatus = "not-scanned";
					order.boxCount += parseInt(quantity);
					item.scannedQuantity += parseInt(quantity);
				}
			}
			quantity = "1";
			return order;
		});
	}

	function handleBundleItems(barcode: string, quantity: string) {
		order.update((orderOld) => {
			const order = JSON.parse(JSON.stringify(orderOld)) as CustomerOrderData;
			let itemFound = false;
			for (let i = 0; i < order.bundles.length; i++) {
				let bundle = order.bundles[i];

				if (bundle.status === "completed") {
					continue;
				}

				if (itemFound) {
					break;
				}

				let item = bundle.bundleItems.find((item) => {
					return (
						(item.ean === barcode || item.ean === "0" + barcode) &&
						item.scannedQuantity < item.orderQuantity
					);
				});

				if (item == null) {
					continue;
				}

				if (
					item.scannedQuantity + parseInt(quantity) >
					item.orderQuantity
				) {
					return order;
				}

				item.scannedQuantity = item.scannedQuantity + parseInt(quantity);
				if (item.scannedQuantity >= item.orderQuantity) {
					item.status = "completed";
				} else {
					item.status = "not-scanned";
				}

				itemFound = true;

				if (
					bundle.bundleItems.filter((item) => item.status === "completed")
						.length === bundle.bundleItems.length
				) {
					bundle.scannedQuantity = bundle.scannedQuantity + 1;
					if (bundle.scannedQuantity < bundle.orderQuantity) {
						bundle.bundleItems.forEach((bundleItem) => {
							bundleItem.status = "not-scanned";
							bundleItem.scannedQuantity = 0;
						});
					}
				}
				if (bundle.scannedQuantity >= bundle.orderQuantity) {
					bundle.status = "completed";
				} else {
					bundle.status = "not-scanned";
				}
				return order;
			}
		})
	}

	let openError = false;
	const toggleError = () => (openError = !openError);

    async function completeOrder() {
        await Espo.Ajax.postRequest(
            "SalesOrder/completeOrder",
            {
                orderId: $order.id,
                scannedItems: $order.items,
                bundles: $order.bundles,
            }
        ) as ApiResponse<any>;
    }
    const toggle = () => {
        if (!open) {
            giftScannedSound.currentTime = 0;
            giftScannedSound.play();
        }
        disableScan = !disableScan;
        return (open = !open);
    };
    const toggleBoxAdd = () => {
        disableScan = !disableScan;
        return (openBoxAdd = !openBoxAdd);
    };
    const toggleComplete = () => {

        if (!isCompleted(forcedComplete)) {
			allowForceComplete = true;
            toggleAlertModal("Objednávka není dokončena");
            return;
        }
        disableScan = !disableScan;
		if (!openComplete) {
			try{
				printerService?.printLabel();
			} catch (e) {
				toggleAlertModal("Nepodařilo se vytisknout štítek: "+ e.message);
			}
		} else {
			forcedComplete = false;
			console.log("zavri");
		}
		return (openComplete = !openComplete);
    };
    const toggleBundleItem = () => {
        if (!openBundleItem) {
            giftScannedSound.currentTime = 0;
            giftScannedSound.play();
        }
        disableScan = !disableScan;
        return (openBundleItem = !openBundleItem);
    };
    const toggleNonExistItem = () => {
        if (!openNonExistItem) {
            giftScannedSound.currentTime = 0;
            giftScannedSound.play();
        }
        disableScan = !disableScan;
        return (openNonExistItem = !openNonExistItem);
    };
    const toggleReturnModal = (alertMessage = "") => {
        message = alertMessage;
        disableScan = !disableScan;
        return (returnModal = !returnModal);
    };
    enum ButtonText {
        "Zobrazit krabice",
        "Skrýt krabice",
    }
</script>

<Modal
    id="modal"
    isOpen={alertModal}
    toggle={toggleAlertModal}
    size="xl"
    centered
>
    <ModalBody>
        <h1>{message}</h1>
        <div class="w-100 d-inline-flex pt-4">
            <Button
                color="secondary"
                class="btn-block w-100 error-item"
                on:click={() => toggleAlertModal()}>Ok</Button
            >
			{#if allowForceComplete}
				<Button
					color="warning"
					class="btn-block w-100 ms-3 error-item"
					on:click={() => {
                        toggleAlertModal();
						forcedComplete = true;
						toggleComplete();
                    }}
				>
					Přesto dokončit
				</Button>
			{/if}
        </div>
    </ModalBody>
</Modal>
<Modal
    id="modal"
    isOpen={returnModal}
    toggle={toggleReturnModal}
    size="xl"
    centered
>
    <ModalBody>
        <h1>{message}</h1>
        <div class="w-100 d-inline-flex pt-4">
            <Button
                color="secondary"
                class="btn-block w-100 error-item"
                on:click={() => {
                    //console.log("pushing");
                    push("/queue");
                    toggleReturnModal();
                }}>Ok</Button
            >
        </div>
    </ModalBody>
</Modal>
<Modal id="modal" isOpen={open} {toggle} size="xl" centered>
    <ModalBody>
        <h1>
            Snaha o přidání více kusů než je objednáno. Chcete přidat {giftItemOverflow}
            kusů jako dárek?
        </h1>
        <div class="w-100 d-inline-flex pt-4">
            <Button
                color="primary"
                class="btn-block w-100 me-3 error-item"
                on:click={() => addGift(giftItem, giftItemOverflow, toggle)}
                >Ano</Button
            >
            <Button
                color="secondary"
                class="btn-block w-100 ms-3 error-item"
                on:click={toggle}>Ne</Button
            >
        </div>
    </ModalBody>
</Modal>
<Modal
    id="modal"
    isOpen={openNonExistItem}
    toggle={toggleNonExistItem}
    size="xl"
    centered
>
    <ModalBody>
        <h1>
            Tento předmět neexistuje v objednávce chcete přidat {nonExistItemEanCount}
            kusů jako dárek?
        </h1>
        <div class="w-100 d-inline-flex pt-4">
            <Button
                color="primary"
                class="btn-block w-100 me-3 error-item"
                on:click={() =>
                    addNonExistingItem(
                        nonExistItemEan,
                        nonExistItemEanCount,
                        toggleNonExistItem,
                        toggleAlertModal
                    )}>Ano</Button
            >
            <Button
                color="secondary"
                class="btn-block w-100 ms-3 error-item"
                on:click={toggleNonExistItem}>Ne</Button
            >
        </div>
    </ModalBody>
</Modal>
<Modal id="modal" isOpen={openBoxAdd} toggle={toggleBoxAdd} size="xl" centered>
    <ModalBody>
        <h1>
            Chcete přidat více krabic({boxOverflowQuantity}) pokud ano
            objednávka bude rozdělena na více kusů?
        </h1>
        <div class="w-100 d-inline-flex pt-4">
            <Button
                color="primary"
                class="btn-block w-100 me-3 error-item"
                on:click={() =>
                    addGift(boxOverflow, boxOverflowQuantity, toggleBoxAdd)}
                >Ano</Button
            >
            <Button
                color="secondary"
                class="btn-block w-100 ms-3 error-item"
                on:click={toggleBoxAdd}>Ne</Button
            >
        </div>
    </ModalBody>
</Modal>

<Modal isOpen={openError} toggle={toggleError} size="xl" centered>
    <ModalHeader toggle={toggleError}>
        <h1>Vyberte prosím důvod proč je objednávka chybná</h1>
    </ModalHeader>
    <ModalBody>
        <Row class="w-100  pt-4">
            <Button
                color="primary"
                class="btn-block w-100 mt-3 error-item"
                on:click={() => {
                    updateOrder("expedition-error", "Nelze naskenovat produkt",toggleReturnModal);
                    toggleError();
                }}
                >Nelze naskenovat produkt
            </Button>
            <Button
                color="secondary"
                class="btn-block w-100 mt-3 error-item"
                on:click={() => {
                    updateOrder("expedition-error", "Chybí produkt",toggleReturnModal);
                    toggleError();
                }}>Chybí produkt</Button
            >
            <Button
                color="secondary"
                class="btn-block w-100 mt-3 error-item"
                on:click={() => {
                    updateOrder("data-error", "Nelze vytisknou štítek",toggleReturnModal);
                    toggleError();
                }}>Nelze vytisknou štítek</Button
            >
        </Row>
    </ModalBody>
</Modal>

<Modal isOpen={openComplete} toggle={toggleComplete} size="xl" centered>
    <ModalHeader toggle={toggleComplete}>
        <h1>Objednávka dokončena</h1>
    </ModalHeader>
    <ModalBody>
        <Row class="w-100  pt-4">
            <Button
                color="success"
                class="btn-block w-100 mt-3 error-item"
                on:click={() => {
                    completeOrder();
                    updateOrder("sent",null,toggleReturnModal);
                    toggleComplete();
                }}>Pokračovat na další objednávku</Button
            >
            <Button
                color="warning"
                class="btn-block w-100 mt-3 error-item"
                on:click={() => {
					if (isCompleted(forcedComplete)) {
						try{
							printerService?.printLabel();
						} catch (e) {
							toggleAlertModal("Nepodařilo se vytisknout štítek: "+ e.message);
						}
					} else {
						toggleAlertModal("Objednávka není dokončena");
					}
                }}>Vytisknout štítek znovu</Button
            >
            <Button
                color="danger"
                class="btn-block w-100 mt-3 error-item"
                on:click={() => {
                    updateOrder("data-error", "Nelze vytisknou štítek",toggleReturnModal);
                    toggleComplete();
                }}>Nelze vytisknou štítek</Button
            >
        </Row>
    </ModalBody>
</Modal>

<Modal
    isOpen={openBundleItem}
    toggle={toggleBundleItem}
    on:close={() =>
        skip ? (skip = !skip) : handleItems(decidedEan, decidedOverflow)}
    size="xl"
    centered
>
    <ModalBody>
        <h1>Chcete přidat naskenovaný produkt do Bundlu nebo do objednávky?</h1>
        <div class="w-100 d-inline-flex pt-4">
            <Button
                color="primary"
                class="btn-block w-100 mt-3 error-item"
                on:click={() => {
                    skip = true;
                    handleBundleItems(decidedEan, decidedOverflow);
                    toggleBundleItem();
                }}
                >Do Bundlu
            </Button>
            <Button
                color="secondary"
                class="btn-block w-100 mt-3 error-item"
                on:click={toggleBundleItem}>Do objednávky</Button
            >
        </div>
    </ModalBody>
</Modal>

{#if $order != null}
	<div
		style="--theme-color: {$order.color}; --theme-background-color: {addAlpha(
        $order.color
    )}; height:fit-content"
		class="expedition-container"
	>


		<!--
		/add me this column if previous shipping label is available
		grid-template-columns: 0.1fr 1fr 0.1fr;-->
		<div class="top-bar"
			 style={$previousShippingLabel
       			? "display: grid; grid-template-columns: 0.1fr 1fr 0.1fr;"
       			: "display: grid; grid-template-columns: 1fr 0.1fr;"
       			}>
			{#if $previousShippingLabel}
				<div class="top-bar-previous">
					<Button
						white
						color="light"
						on:click={() => {

							try{
								printerService?.printPreviousLabel();
							} catch (e) {
								toggleAlertModal("Nepodařilo se vytisknout štítek: "+ e.message);
							}
						}}
					>
						Vytisknout předchozí štítek
					</Button>
				</div>
			{/if}
			<div class="top-bar-order">
				{$order.orderNumber}|{$order.carrier}
			</div>

			<div class="top-bar-exit">
				<Button
					style="width: 100%;"
					white
					color="light"
					on:click={unrelatedWarehouseWorker}
				>
					Odejít
				</Button>
			</div>
		</div>
		{#if $printServiceStatus !== "Připojená"}
			<div style="text-align: center;font-size: larger;background-color: red;">Stav tiskárny:{$printServiceStatus}</div>
		{/if}
		<div class="customer-header-bar">
			<div class="customer-header-bar-item">
				Zákazník: {$order.firstName}
				{$order.lastName}
			</div>
			<div class="customer-header-bar-item">
				{$order.quantity} ks / {$order.totalPrice}
				{$order.totalPriceCurrency}
			</div>
		</div>

		{#if $order.customerNote}
			<div class="customer-note">
				Poznámka od zákazníka: {$order.customerNote}
			</div>
		{/if}
		{#if $order.internalNote}
			<div class="internal-note">
				Interní poznámka: {$order.internalNote}
			</div>
		{/if}
		{#if $order.customerLevel === "Wholesale"}
			<QuantitySelect bind:quantity />
		{/if}

		<div class="bundle">
			{#each $order.bundles as bundle}
				<BundleElement bind:bundle />
			{/each}
		</div>

		<div class="items">
			{#each $order.items as item}
				{#if item.type !== "KRABICE"}
					<OrderItem bind:item />
				{/if}
			{/each}
		</div>
		<div class="box-collection {$order.boxStatus}">
			<div class="item-image">
				<img
					src="client/modules/expedition-portal/lib/expedition-portal/box.png"
					alt="KRABICE"
					width="100"
				/>
			</div>
			{#if $boxRowsOpen}
				<div
					transition:slide
					class="box-header"
					style="margin-right: 2rem;"
				>
					{#each $order.items as item}
						{#if item.type === "KRABICE"}
							<BoxRow
								bind:item
							/>
						{/if}
					{/each}
				</div>
			{/if}
			<div
				class="box-header"
				style="text-align: center; margin-right: 3.5rem; grid-column: 3"
			>
				{$order.boxCount ?? 0} / 1
			</div>
			<Button
				class="close-btn btn-block w-100 error-item "
				on:click={() => ($boxRowsOpen = !$boxRowsOpen)}
			>{ButtonText[$boxRowsOpen ? 1 : 0]}</Button
			>
		</div>
		<div class="bottom-bar">
			<Button
				class="something-missing-button"
				on:click={toggleError}
				style="width: 30%;
                height: 180px;
                background-color: #e31d39;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;"
			>
				<span class="button-text">Něco chybí</span>
			</Button>
			<Button
				class="print-label-button"
				style="width: 70%;
                margin-left: 20px;
                height: 180px;
                background-color: #1a903d;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;"
				on:click={() => toggleComplete()}
			>
            <span class="button-text">
                Vytisknout štítek
                <img
					class="printer-icon"
					alt=""
					src="client/modules/expedition-portal/lib/expedition-portal/printer-svgrepo-com.svg">
            </span>
			</Button>
		</div>
	</div>
{:else}
	<div>
		<h1>Načítání objednávky</h1>
	</div>
{/if}

<style>
	:global(body) {
		margin: 0;
		padding: 0;
		font-size: 16px;
	}

	.printer-icon {
		width: 30px;
		height: 30px;
		margin-left: 10px;
		max-width: 100%;
		max-height: 100%;
	}

	:global(.error-item) {
		height: 200px;
		font-size: x-large;
	}

	:global(.close-btn) {
		grid-column: span 3;
		margin-top: 2em;
	}

	.expedition-container {
		background-color: var(--theme-background-color);
	}

	.box-collection {
		display: grid;
		grid-template-columns: auto 1fr auto;
		align-items: center;
		margin: 1rem;
		padding: 0.5rem;
	}

	.box-collection.completed {
		background: yellowgreen;
	}

	.box-collection.not-scanned {
		background: #f4f5d4;
	}

	.box-header {
		font-size: 2rem;
		font-weight: 700;
	}

	.top-bar {
		display: grid;
		justify-content: space-between;
		align-items: center;
		justify-items: center;
		background-color: var(--theme-color);
		color: white;
		padding: 3px 0;
		font-size: 1.2rem;
	}

	.top-bar-previous {
		margin-left: 1rem;
		white-space: nowrap;
	}

	.top-bar-exit {
		margin-right: 1rem;
	}

	.top-bar-order {
		font-size: xx-large;
		margin-left: 1rem;
	}

	.customer-header-bar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: white;
		padding: 0.5rem;
	}

	.customer-header-bar-item {
		font-size: 1.2rem;
		font-weight: bold;
	}

	.customer-note {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: yellow;
		font-size: 1.2rem;
		font-weight: bold;
		padding: 0.5rem;
	}

	.internal-note {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: violet;
		font-size: 1.2rem;
		font-weight: bold;
		padding: 0.5rem;
	}

	.bottom-bar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 1rem;
	}

	.something-missing-button {
		width: 25%;
		height: 180px;
		background-color: red;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
	}

	.print-label-button {
		width: 75%;
		height: 180px;
		background-color: green;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 8px;
	}

	.button-text {
		color: white;
		font-size: 1.5rem;
		font-weight: bold;
	}
</style>
