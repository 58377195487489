<script>
	const stylesheets = [...document.getElementsByTagName("link")];
	export let webSocketUrl;
	export let printServiceUrl;
	export let defaultShippingLabel;

	for (let i in stylesheets) {
	const sheet = stylesheets[i];
	const rel = sheet.getAttribute("rel");

	if (!!rel && rel.toLowerCase() === "stylesheet")
	  sheet.parentNode.removeChild(sheet);
	}
	// Hack na odstranění CSS styles Espa
	import Router from "svelte-spa-router";

	import Home from "./pages/Home.svelte";
	import QueueSelection from "./pages/QueueSelection.svelte";
	import Expedition from "./pages/Expedition.svelte";
	import { printServiceUrl as storePrintServiceUrl,
		defaultShippingLabel as storeDefaultShippingLabel,
		defaultPrinterName as storeDefaultPrinterName
	} from './stores';


	storePrintServiceUrl.set(printServiceUrl);
	storeDefaultShippingLabel.set(defaultShippingLabel);
	// load the printer query parameter
	const urlParams = new URLSearchParams(window.location.search);
	const printer = urlParams.get("printer");
	storeDefaultPrinterName.set(printer);
	const routes = {
		"/": Home,
		"/queue": QueueSelection,
		"/expedition": Expedition,
	};
</script>

<svelte:head>
	<link
		rel="stylesheet"
		href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/css/bootstrap.min.css"
	/>
	<link
		rel="stylesheet"
		href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css"
	/>
	<link
		rel="stylesheet"
		href="/client/modules/expedition-portal/lib/expedition-portal/style.css"
	/>
	</svelte:head>
	<Router {routes} />

<style>
	:global(.row) {
	--bs-gutter-x: 0;
	}
	:global(.col) {
	--bs-gutter-x: 1.5rem;
	}
</style>
