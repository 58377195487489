<script lang="ts">
	import { onMount, afterUpdate } from 'svelte';
	import { Col, Row, Jumbotron } from '@sveltestrap/sveltestrap';
	import type { ApiResponseSuccess, ProductionCategory, Product, ApiResponseError } from '../types/types';
	import ProductCard from '../components/ProductCard.svelte';
	import SelectedProductsList from '../components/SelectedProductsList.svelte';
	import { sendCompleteProductionData } from '../utils/productionUtils';
	import {
		worker,
		products,
		categorySelected,
		categories,
		filteredProducts
	} from '../stores';
	import { push } from 'svelte-spa-router';

	export let params; // Receive 'params' from the router

	// Reactive variables from stores
	$: $worker;
	$: $products;
	$: $categorySelected;
	$: $categories;
	$: $filteredProducts;

	// Fetch data on component mount
	onMount(async () => {
		// Redirect to home if no worker is set
		if ($worker === null) {
			categorySelected.set(null);
			push('/');
			return;
		}

		// Fetch products
		try {
			const response = await Espo.Ajax.getRequest(`Product/productionProducts/${$worker}`);
			const data = (response as ApiResponseSuccess<Product[]>).data;
			products.set(data);
		} catch (error) {
			console.error('Failed to fetch products:', error);
		}

		// Fetch categories
		try {
			const response = await Espo.Ajax.getRequest('Product/Categories');
			const data: ApiResponseSuccess<ProductionCategory[]> = response as ApiResponseSuccess<ProductionCategory[]>;
			if (data.success) {
				categories.set(data.data);
			} else {
				console.error('Failed to fetch categories:', (response as ApiResponseError).message);
			}
		} catch (error) {
			console.error('Error occurred while fetching categories:', error);
		}
	});

	// Reactive statement to set the selected category based on 'categoryId'
	$: {
		const categoryId = params?.categoryId || null;
		if (categoryId && $categories.length > 0) {
			const selected = findCategoryById(categoryId, $categories);
			if (selected) {
				categorySelected.set(selected);
			} else {
				console.error('Category not found:', categoryId);
				push('/production'); // Redirect to base if category not found
			}
		} else {
			categorySelected.set(null); // Reset if no categoryId
		}
	}

	/**
	 * Utility function to find a category by ID within a hierarchical structure.
	 * @param id The ID of the category to find.
	 * @param categories The list of categories to search within.
	 * @returns The found category or undefined.
	 */
	function findCategoryById(id: string, categories: ProductionCategory[]): ProductionCategory | undefined {
		for (const category of categories) {
			if (category.id === id) {
				return category;
			}
			if (category.children && category.children.length > 0) {
				const found = findCategoryById(id, category.children);
				if (found) return found;
			}
		}
		return undefined;
	}

	/**
	 * Opens the product page for the selected product.
	 * @param product The selected product.
	 */
	const openProductPage = (product: Product) => {
		push(`/product/${product.id}`);
	};

	/**
	 * Selects a category to display its products.
	 * @param category The selected category.
	 */
	const selectCategory = (category: ProductionCategory) => {
		push(`/production/${category.id}`);
	};

	/**
	 * Navigates back to the parent category or base production route.
	 */
	const goBack = () => {
		if ($categorySelected && $categorySelected.parentId) {
			push(`/production/${$categorySelected.parentId}`);
		} else {
			push('/production');
		}
	};

	/**
	 * Logs out the user by resetting relevant stores and redirecting to the home page.
	 */
	function logoutUser() {
		categorySelected.set(null);
		worker.set(null);
		push("/");
	}

	/**
	 * Completes the production process by sending data and resetting stores.
	 */
	async function completeProduction() {
		await sendCompleteProductionData();
		worker.set(null);
		products.set([]);
		push('/');
	}
</script>

<main>
	<Row>
		<Col>
			<Jumbotron>
				<!-- Logout Button -->
				<button
					class="btn btn-outline-danger m-2"
					on:click={logoutUser}
				>
					Odhlásit se
				</button>

				<!-- Display Selected Category and Queue Status -->
				{#if $categorySelected || $products.filter(p => p.producedQuantity > 0).length > 0}
					<Row>
						<Col class="selected-category">
							{#if $categorySelected}
								<h2>Kategorie: {$categorySelected.name}</h2>
							{/if}
							{#if $products.filter(p => p.producedQuantity > 0).length > 0}
								<h2 style="color: #ff3f4b; text-align: center">
									Produkty jsou stále ve frontě
								</h2>
							{/if}
						</Col>
					</Row>
					<hr />
				{/if}

				<!-- Category Selection or Subcategory Display -->
				{#if !$categorySelected}
					<Row class="products-grid">
						{#each $categories as category}
							{#if category.parentId === null} <!-- Display only top-level categories -->
								<div
									class="col production-category"
									style="width: 100%"
									role="button"
									tabindex="0"
									on:click={() => selectCategory(category)}
									on:keydown={(e) => { if (e.key === 'Enter') selectCategory(category) }}
								>
									<img
										class="production-image img-fluid"
										src={category.picture}
										alt={category.name}
										loading="lazy"
									/>
									<span class="category-tile">
										{category.name}
									  </span>
								</div>
							{/if}
						{/each}
					</Row>
				{:else}
					{#if $categorySelected.children.length > 0}
						<div class="categories-section">
							<Row class="products-grid">
								{#each $categorySelected.children as category}
									<div
										class="col production-category"
										style="width: 100%"
										role="button"
										tabindex="0"
										on:click={() => selectCategory(category)}
										on:keydown={(e) => { if (e.key === 'Enter') selectCategory(category) }}
									>
										<img
											class="production-image"
											src={category.picture}
											alt={category.name}
											loading="lazy"
										/>
										<span class="category-tile">
                      {category.name}
                    </span>
									</div>
								{/each}
							</Row>
						</div>
					{/if}
				{/if}

				<!-- Products Display Section -->
				<div class="products-section">
					{#if $categorySelected}
						<Row>
							<Col>
								<button
									style="width: 100%;"
									class="btn btn-secondary btn-block btn-lg mb-4 enlarged-button"
									on:click={goBack}
								>
									Zpět
								</button>
							</Col>
						</Row>
					{/if}
					<Row class="products-grid">
						{#each $filteredProducts as product}
							<Col>
								<ProductCard
									{product}
									on:select={() => openProductPage(product)}
								/>
							</Col>
						{/each}
					</Row>
				</div>
				<hr />
				<!-- Selected Products List -->
				<SelectedProductsList />

				<!-- Complete Production Button -->
				{#if $products.filter(p => p.producedQuantity > 0).length > 0}
					<Row class="mt-4">
						<button
							class="btn btn-primary enlarged-button"
							style="width: 100%"
							on:click={completeProduction}
						>
							Dokončit
						</button>
					</Row>
				{/if}
			</Jumbotron>
		</Col>
	</Row>
</main>

<style>
	:global(.products-grid) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
	}
	:global(.full-button) {
		width: 100%;
		height: 100%;
	}
	:global(.category-tile) {
		color: #ffffff;
		height: 125px;
		font-size: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.categories-section h2 {
		font-size: 2rem;
	}

	.products-section h2 {
		font-size: 1.75rem;
	}

	:global(.enlarged-button) {
		font-size: 1.2rem;
		padding: 0.75rem 1.5rem;
		height: 100px;
	}

	:global(.full-button.enlarged-button) {
		height: 100px;
	}
	.selected-category {
		text-align: center;
	}
	:global(.production-category) {
		width: 100%;
		background-color: #0d6efd;
		display: flex;
		justify-content: space-between;
		flex-flow: column;
	}
	:global(.production-image) {
		margin: 20px auto;
		display: flex;
		max-width: 300px;
		justify-content: space-between;
	}
	:global(.row > *) {
		margin-right: calc(var(--bs-gutter-x) * .5);
		margin-left: calc(var(--bs-gutter-x) * .5);
		padding-right: 0;
		padding-left: 0;
	}
	:global(.products-grid) {
		margin-bottom: 1rem;
	}
</style>
