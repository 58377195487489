<script lang="ts" >
    import type { ButtonColor } from "../types/types";

    let buttonProps = {
        class: $$restProps.class,// No need for []
        style: $$restProps.style // No need for []
    };
    export let color: ButtonColor = "secondary";
    function preventEnter(event: KeyboardEvent) {
        console.log(event.key);
        event.preventDefault();
    }
</script>

<button
    on:click
    on:keydown={preventEnter}
    on:keyup={preventEnter}
    class="btn btn-{color} {buttonProps.class}"
    style="{buttonProps.style}"
    >
    <slot />
</button>

