<script lang="ts">
    import { link } from "svelte-spa-router";
    import { Col } from "@sveltestrap/sveltestrap";
    import { worker, market } from "../stores";
    import type {Market} from "../types/types";

    export let id: string;
    export let columnsCount: number;
    export let workerMarket: Market;
    export let name = "zamestnanec";
    export let imageUrl = "https://placehold.co/400";

    function loginUser() {
        worker.set(id);
        market.set(workerMarket);

    }
</script>

<Col style="max-width: calc( 100% / {columnsCount})">
	<a
		href="/queue"
		use:link
		on:click={loginUser}
		class="text-decoration-none text-dark"
		style="display: block; cursor: pointer;"
		aria-label={`Navigate to production page for ${name}`}
	>
		<div class="card mt-2">
			<img src={imageUrl} class="card-img-top" alt={`Image of ${name}`} />
			<div class="card-body">
				<h5 class="card-title mb-4 mt-2 text-center">{name}</h5>
				<div class="col text-center">
					<span class="btn btn-primary btn-lg btn-block">
						<span>Přihlásit se</span>
					</span>
				</div>
			</div>
		</div>
	</a>
</Col>

<style>

	img.card-img-top {
		max-height: 864px;
		height: auto;
		max-width: 632px;
		margin: 20px auto;
	}
	@media (max-width: 768px) {
		img.card-img-top {
			margin: 0 auto;
		}
	}
	span.btn-primary {
		width: 100%;
		height: 3em;
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		font-size: x-large;
	}
	span {
		height: fit-content;
	}

	h5.card-title {
		font-size: xx-large;
	}

</style>
