import { writable } from 'svelte/store';
import type {
	CustomerOrderData,
	PreviousShippingLabel,
	PrinterSettings,
} from './types/types';

export const worker = writable(null);
export const market = writable('CZ');
export const queue = writable(null);
export const order = writable<CustomerOrderData | null>(null);

// handle the previous order label that has been printed
export const previousShippingLabel = writable<PreviousShippingLabel | null>(
	null,
);
export const printServiceStatus = writable<string>('Odpojená');
export const printServiceUrl = writable<string | null>(null);

// this is the printer name that is set in the url params
export const defaultPrinterName = writable<string | null>(null);
// printer settings loaded from Printer entity
export const printerSettings = writable<PrinterSettings | null>(null);

//all available printers that are available to connect to
export const availablePrinters = writable<PrinterSettings[]>([]);

// id of default shipping label template
export const defaultShippingLabel = writable<string | null>(null);

// handle the state of the box rows
export const boxRowsOpen = writable<boolean>(true);
