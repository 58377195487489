<script lang="ts">
	import { Card, CardBody, CardTitle } from '@sveltestrap/sveltestrap';
	import type { Product } from '../types/types';
	import { createEventDispatcher } from 'svelte';

	export let product: Product;
	const dispatch = createEventDispatcher();

	// Handle kliknutí nebo stisknutí klávesy Enter/Space pro přístupnosti
	function handleClick() {
		dispatch('select', product);
	}

	function handleKeydown(event: KeyboardEvent) {
		if (event.key === 'Enter' || event.key === ' ') {
			event.preventDefault();
			dispatch('select', product);
		}
	}
</script>

<div
	class="product-card"
	style="height: 100%"
	role="button"
	tabindex="0"
	on:click={handleClick}
	on:keydown={handleKeydown}
>
	<Card style="height: 100%">
		<div class="image-container">
			<img src={product.image} class="card-img-top" alt={product.name} />
		</div>
		<CardBody>
			<CardTitle class="text-center h5">{product.name}</CardTitle>
			<div class="product-info text-center">
				<!-- Zakomentováno EAN -->
				<!-- <span>EAN: {product.ean}</span><br /> -->
				<!-- Zakomentováno dostupné množství -->
				<!-- <span>Dostupné množství: {product.quantity}</span><br /> -->
				<!-- Zakomentováno požadované množství -->
				<!-- <span>Požadované množství: {product.minimumStockQuantity}</span> -->
			</div>
		</CardBody>
	</Card>
</div>

<style>
	.product-card {
		height: 100%;
		width: 100%;
		margin-top: 0.5rem;
		cursor: pointer;
		outline: none;
	}

	.image-container {
		height: 375px; /* Zmenšeno z 416px na 375px */
		display: flex;
		justify-content: center;
		align-items: center;
		margin: auto;
	}

	.card-img-top {
		max-width: 100%;
		max-height: 100%;
		border-radius: 8px;
	}

	.product-info {
		margin-top: 1rem;
	}
</style>
