<script>

  import ProductPage from './pages/ProductPage.svelte';

  const stylesheets = [...document.getElementsByTagName("link")];

  for (let i in stylesheets) {
    const sheet = stylesheets[i];
    const rel = sheet.getAttribute("rel");

    if (!!rel && rel.toLowerCase() === "stylesheet")
      sheet.parentNode.removeChild(sheet);
  }
  // Hack na odstranění CSS styles Espa
  import Router from "svelte-spa-router";
  import { SvelteToast } from '@zerodevx/svelte-toast';
  import Home from "./pages/Home.svelte";
  import Production from "./pages/Production.svelte";
  const routes = {
	  "/": Home,
	  "/production": Production,
	  "/production/:categoryId": Production,
	  '/product/:id': ProductPage,
  };
</script>

<SvelteToast
	position="top-right"
	duration={5000}
	theme={{
    '--toastBackground': '#fff',
    '--toastColor': '#333',
    '--toastBorderRadius': '5px',
    '--toastPadding': '1rem',
    '--toastBoxShadow': '0 2px 10px rgba(0, 0, 0, 0.1)'
  }}
/>

<svelte:head>
	<link
		rel="stylesheet"
		href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/css/bootstrap.min.css"
	/>
	<link
		rel="stylesheet"
		href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css"
	/>
	<link
		rel="stylesheet"
		href="/client/modules/production-portal/lib/production-portal/style.css"
	/>
</svelte:head>

<Router {routes} />

<style>
	main {
		padding: 1rem;
	}
	/* Adjust gutter settings to fix row overflow issues */
	:global(.row) {
		--bs-gutter-x: 0;
	}
	:global(.col) {
		--bs-gutter-x: 1.5rem;
	}
</style>
