<script lang="ts">
	import { products } from '../stores';
	import { Button, Table } from '@sveltestrap/sveltestrap';
	import { updateProductsAndSendData } from '../utils/productionUtils';
	import { push } from 'svelte-spa-router';

	/**
	 * Removes the producedQuantity for a specific product by setting it to 0.
	 * @param productId - The ID of the product to update.
	 */
	function removeProducedQuantity(productId: string) {
		updateProductsAndSendData((currentProducts) =>
			currentProducts.map((p) =>
				p.id === productId ? { ...p, producedQuantity: 0 } : p
			)
		);
	}

	const viewProduct = (productId: string) => {
		push(`/product/${productId}`);
	};
</script>

<div class="selected-products-list">
	<h2>Vyrobené Produkty</h2>
	{#if $products.filter(p => p.producedQuantity > 0).length > 0}
		<Table striped>
			<thead>
			<tr>
				<th>Produktu</th>
				<!-- Zakomentováno EAN -->
				<!-- <th>EAN</th> -->
				<!-- Zakomentováno dostupné množství -->
				<!-- <th>Dostupné množství</th> -->
				<th>Množství</th>
				<th></th>
			</tr>
			</thead>
			<tbody>
			{#each $products.filter(p => p.producedQuantity > 0) as product}
				<tr class="product-row clickable-row"
					on:click={() => viewProduct(product.id)}
					style="cursor: pointer;"
				>
					<td class="product">
						<img src={product.image} alt="" style="width: 150px">
						<span>{product.name}</span>
					</td>
					<td>{product.producedQuantity}</td>
					<td>
						<Button style="width: 100%" color="danger" size="lg" on:click={() => removeProducedQuantity(product.id)}>
							Odebrat
						</Button>
					</td>
				</tr>
			{/each}
			</tbody>
		</Table>
	{:else}
		<p>Žádné vybrané produkty.</p>
	{/if}
</div>

<style>
	.selected-products-list {
		margin-top: 2rem;
	}


	th,td {
		vertical-align: middle;
		font-size: x-large;
		font-weight: bold;
	}

	:global(.btn) {
		font-size: 1.2rem;
		padding: 0.75rem 1.5rem;
	}
	.product-row {
		vertical-align: middle;
	}
	.product {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 400px; /* Adjust as needed */
	}
</style>
